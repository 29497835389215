<template>
  <Container :title="$t('onboarding.addNewCompany')">
    <CompanyForm create @next="success"/>
  </Container>
</template>
<script>
import CompanyForm from "../components/CompanyForm"

import Vue from 'vue'

export default Vue.extend({
  name: "CompanyCreate",
  components: {
    CompanyForm,
  },
  methods: {
    success() {
      this.$router.push({ name: "Onboarding" })
    },
  },
})
</script>
